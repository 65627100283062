@tailwind base;
@tailwind components;
@tailwind utilities;


@import 'primereact/resources/themes/saga-blue/theme.css';   /* theme */
@import 'primereact/resources/primereact.min.css';            /* core css */
@import 'primeicons/primeicons.css';                          /* icons */


/* Custom styles */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
body {
 
  font-family: 'Inter', sans-serif;
}


